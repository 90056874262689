import { inView, animate } from "motion"

const container = document.getElementById("dotty")
const elements = container.querySelectorAll("svg")
const colourchip = document.currentScript.getAttribute('data-colourchip');

inView(
  container,
  ({ target }) => {
    animate(
      "#drip",
      { transform: "translateY(20px)" },
      { delay: 0.3, duration: 2.7, easing: [0.65, 0, 0.35, 1] }
    ),animate(
      elements,
      {
        fill: colourchip,
      },
      { delay: 0.25, duration: 2.3, easing: [0.65, 0, 0.35, 1] }
    );
  },
  { amount: 0.6 },
)
